import React, { useState } from "react"
// import axios from 'axios'
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import CustomBlogBanner from '../components/blog-banner'
import BlogCard from '../components/blog-card'
import { graphql } from "gatsby"
import Moment from 'react-moment'
// import GraphImg from "graphcms-image"

const BlogHome = ({ data }) => {
  const [visiblePosts, setVisiblePosts] = useState(30)

  const [numberOfPosts, setNumberOfPosts] = useState(data.gcms.blogPosts.length)

  const loadMore = () => {
    setVisiblePosts(prevState => prevState + 30)
  }

  return (
    <Layout>
      <SEO 
        title="The Billsby Blog | Latest insights from the subscription economy" 
        description="Learn about the latest subscription billing and recurring payments news with our in-depth and frequently updated help and guidance." 
        url="https://www.billsby.com/subscription-economy-blog"
      />

      <CustomBlogBanner />

      <div className="section-blog-list">
        <div className="container">
          <div className="list-wrapper">
            {
              data.gcms.blogPosts.slice(0, visiblePosts).map(post => {
                const date = new Date(post.publicationDate)

                const appendLeadingZeroes = (n) => {
                  if(n <= 9) {
                    return "0" + n;
                  }
                  return n
                }

                const formattedDate = date.getFullYear() + "/" + appendLeadingZeroes(date.getMonth() + 1) + "/" + appendLeadingZeroes(date.getDate())

                var url = formattedDate + "/" + post.slug
                var cat = post.blogCategory !== null ? post.blogCategory.categoryName : null
                var catSlug = post.blogCategory !== null ? post.blogCategory.slug : null

                return (
                  <BlogCard 
                    // featuredImage={post.featuredImage.url}
                    key={post.id}
                    image={post.image}
                    category={cat}
                    categorySlug={catSlug}
                    title={post.title}
                    url={url}
                    date={ <Moment format="MMMM DD YYYY">
                        { post.publicationDate }
                      </Moment>}
                  />
                )
              })
            }
          </div>
          <div className="btn-wrapper">
            {
              visiblePosts < numberOfPosts &&
              <a className="btn-blue" onClick={loadMore}>Load more posts</a>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogHome

export const query = graphql`
query BlogHomeTemplate {
  gcms {
    blogPosts(orderBy: publicationDate_DESC, stage: PUBLISHED) {
      id
      blogCategory {
        categoryName
        slug
      }
      title
      slug
      publicationDate
      image: featuredImage {
        handle
        width
        height
      }
    }
  }
}
`
