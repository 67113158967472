import React from "react"
import { Link } from "gatsby"
import GraphImg from "graphcms-image"

const BlogCard = props => (
  <div className="blog-card fade-in">
    <div
      className="featured-image"
      // style={{ backgroundImage: `url(${props.featuredImage})`}}
    >
      <GraphImg image={props.image} maxWidth={413} />
    </div>
    <div className="details-wrapper">
      {props.category !== null && (
        <Link
          to={`/category/${props.categorySlug}`}
          className="btn-category category"
        >
          {props.category}
        </Link>
      )}
      <Link to={"/" + props.url} className="heading-2">
        {props.title}
      </Link>
      <p className="subheading-1">{props.date}</p>
    </div>
  </div>
)

export default BlogCard
